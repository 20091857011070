<template>
  <div class="params">
    <page-search
      ref="pageSearchRef"
      :searchFormConfig="searchFormConfig"
      :pageTitle="$t('general.router-parameter-capk')"
      @resetBtnClick="handleResetClick"
      @queryBtnClick="handleQueryClick"
    >
    </page-search>
    <page-content
      style="margin-top: 10px"
      ref="pageContentRef"
      v-bind="tableOptionsConfig"
      @newBtnClick="handleNewData"
      @editBtnClick="handleEditData"
      @viewBtnClick="handleViewData"
      @pageInfoChange="pageInfoChangeAction"
    >
      <template #otherHandler="scope">
        <el-button
          v-if="isSwitch && isNormalStatus(scope.row.status)"
          class="opt-btn"
          icon="el-icon-lock"
          size="mini"
          plain
          type="warning"
          @click="handleDisableClick(scope.row)"
          >{{ $t('general.disable') }}</el-button
        >
        <el-button
          v-if="isSwitch && !isNormalStatus(scope.row.status)"
          class="opt-btn"
          icon="el-icon-unlock"
          size="mini"
          plain
          @click="handleEnableClick(scope.row)"
          >{{ $t('general.enable') }}</el-button
        >
      </template>
    </page-content>
  </div>
</template>

<script setup>
import { ref, computed, getCurrentInstance } from 'vue'

import pageSearch from '@/components/PageSearch/page-search'
import pageContent from '@/components/PageContent/page-content.vue'

import { searchFormConfig } from './config/param.form'
import { contentTableConfig } from './config/param.table'
import { useRouter } from 'vue-router'
import _ from 'lodash'
import { usePermission } from '@/hooks/use-permission'

// 表格基础配置项
const tableOptions = {
  moduleName: 'parameter', // 所属的模块
  pageName: 'capk', // 所属的页面
  contentTableConfig,
  pageAction: 'param/getCapkLists', // 获取表格的Action
  deleteRecordAction: 'param/deleteCapkById',
  pageCountGetter: 'param/pageListCount', // 获取表格记录数据总和getters
  pageListGetter: 'param/pageListData' // 获取表格列表数据getters
}

const tableOptionsConfig = computed(() => tableOptions)

const pageContentRef = ref('')
const pageSearchRef = ref('')

// 获取页面操作的权限
const isSwitch = usePermission(
  tableOptions.moduleName,
  tableOptions.pageName,
  'switch'
)

const handleQueryClick = (queryInfo) => {
  const queryData = _.cloneDeep(queryInfo)
  queryData.tenantId = queryInfo.tenantId ? queryInfo.tenantId.join(',') : ''
  pageContentRef.value.getPageData(queryData)
}

const handleResetClick = () => {
  pageContentRef.value.getPageData()
}
const router = useRouter()

const handleEditData = (id) => {
  router.push(`/parameter/capk/edit/${id}`)
}

const handleViewData = (id) => {
  router.push(`/parameter/capk/view/${id}`)
}

const handleNewData = () => {
  router.push(`/parameter/capk/new`)
}
const pageInfoChangeAction = () => {
  const formData = pageSearchRef.value.getCurrentFieldValues()
  formData.tenantId = formData.tenantId ? formData.tenantId.join(',') : ''
  pageContentRef.value.getPageData(formData)
}

const isNormalStatus = (status) => {
  return status === '00'
}
const { appContext } = getCurrentInstance()
const handleMessageCommit =
  appContext.config.globalProperties.$handleMessageCommit
const handleDisableClick = async (row) => {
  handleMessageCommit(
    'param/handleTerminalParamCapkDisableAction',
    row.id
  ).then(() => {
    pageContentRef.value.getPageData()
  })
}
const handleEnableClick = async (row) => {
  handleMessageCommit('param/handleTerminalParamCapkEnableAction', row.id).then(
    () => {
      pageContentRef.value.getPageData()
    }
  )
}
</script>
